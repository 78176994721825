import React, { useEffect, useState } from "react";
import { collection, getDocs, query, orderBy, limit, where } from "firebase/firestore";
import { db } from "../firebase-config";
import { Link } from "react-router-dom";
import "./CSS/Home.css"; // Stildatei, um die Anpassungen vorzunehmen

const Home = () => {
  const [latestPost, setLatestPost] = useState(null);
  const [nextEvent, setNextEvent] = useState(null);

  useEffect(() => {
    const fetchLatestPost = async () => {
      const postsCollectionRef = collection(db, "posts");
      const q = query(postsCollectionRef, orderBy("timestamp", "desc"), limit(1));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        setLatestPost(querySnapshot.docs[0].data());
      }
    };

    const fetchNextEvent = async () => {
      const eventsCollectionRef = collection(db, "events");
      const today = new Date().toISOString().split('T')[0];
      const q = query(eventsCollectionRef, where("date", ">=", today), orderBy("date", "asc"), limit(1));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        setNextEvent(querySnapshot.docs[0].data());
      } else {
        setNextEvent(null);
      }
    };

    fetchLatestPost();
    fetchNextEvent();
  }, []);

  const extractText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const getPreviewText = (text, lines) => {
    const lineArray = text.split("\n").slice(0, lines);
    return lineArray.join(" ");
  };

  const formatDate = (date) => {
    const options = { day: "numeric" };
    const day = new Date(date).toLocaleDateString("de-DE", options);
    const month = new Date(date).toLocaleString("de-DE", { month: "short" }).toUpperCase();
    return { day, month };
  };

  return (
    <div className="homeContainer">
      <div className="nextEvent">
        <h2>Nächster Auftritt</h2>
        {nextEvent ? (
          <div className="eventCard"> {/* Nutze die gleichen Stile wie in CalendarView */}
            <div className="eventCardDate">
              <span className="eventDay">{formatDate(nextEvent.date).day}</span>
              <span className="eventMonth">{formatDate(nextEvent.date).month}</span>
            </div>
            <div className="eventCardContent">
              <div className="eventCardHeader">
                <h3>{nextEvent.name}</h3>
              </div>
              <div className="eventCardBody">
                <p>{nextEvent.location}</p>
                <p>{new Date(nextEvent.date).toLocaleDateString()} um {nextEvent.time} Uhr</p>
              </div>
            </div>
            {nextEvent.imageUrl && (
              <div className="eventCardImage">
                <img src={nextEvent.imageUrl} alt="Event" height={100} />
              </div>
            )}
          </div>
        ) : (
          <p>Im Moment haben wir keine weiteren Auftritte geplant.</p>
        )}
      </div>

      <div className="latestPost">
        <h2>Aktuelles</h2>
        {latestPost ? (
          <div className="postPreview">
            <h3>{latestPost.title}</h3>
            <p>{new Date(latestPost.timestamp.seconds * 1000).toLocaleDateString()}</p>
            {latestPost.imageUrls && latestPost.imageUrls.length > 0 && (
              <img src={latestPost.imageUrls[0]} alt="Post preview" className="postImagePreview" />
            )}
            <p className="postTextPreview">
              {getPreviewText(extractText(latestPost.postText), 3)}...
              <Link to="/aktuelles"> Mehr</Link>
            </p>
          </div>
        ) : (
          <p>Lädt...</p>
        )}
      </div>
    </div>
  );
};

export default Home;
