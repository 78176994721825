// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore" ;
import {GoogleAuthProvider, getAuth} from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBTgveP_qnpE0FV3bHeKrU9NeUE8qG4FCY",
  authDomain: "website-b62b2.firebaseapp.com",
  projectId: "website-b62b2",
  storageBucket: "website-b62b2.appspot.com",
  messagingSenderId: "764840957999",
  appId: "1:764840957999:web:febb127200b9c87b8aceda"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
const storage= getStorage(app);

export { app, auth, db,provider,storage};