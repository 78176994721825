import React from 'react';
import './CSS/Impressum.css'; // Importieren Sie die CSS-Datei

const Impressum = () => {
  return (
    <div className="impressumContainer">
      <h1>Impressum</h1>
      <section>
        <h2>Kontakt:</h2>
        <p>
          <strong>Jiri Mares</strong><br />
          mobil: 0171 9523498<br />
          <strong>Ernst-Otto Geiger</strong><br />
          mobil: 0176 64398742<br />
          Email: <a href="mailto:vorstandschaft@bibajima.de">vorstandschaft@bibajima.de</a>
        </p>
      </section>

      <section>
        <h2>Angaben gemäß § 5 TMG:</h2>
        <p>
          Big Band Jiri Mares e. V.<br />
          z.Hd. Ernst-Otto Geiger<br />
          Neumarkter Straße 82a<br />
          81673 München<br />
          Vertreten durch: Christine Frank
        </p>
        <p>
          Telefon: 0157/82817013<br />
          Email: <a href="mailto:webmaster@bibajima.de">webmaster@bibajima.de</a>
        </p>
        <p>
          Registereintrag:<br />
          Eintragung im Vereinsregister.<br />
          Registergericht: Amtsgericht München<br />
          Registernummer: 204012
        </p>
      </section>

      <section>
        <h2>Haftungsausschluss (Disclaimer)</h2>
        <h3>Haftung für Inhalte</h3>
        <p>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
        </p>
        <h3>Haftung für Links</h3>
        <p>
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        </p>
      </section>

      <section>
        <h2>Urheberrecht</h2>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </p>
      </section>

      <section>
        <h2>Datenschutzerklärung gemäß Art. 13 u. 14 DSGVO (Datenschutz-Grundverordnung)</h2>
        <h3>1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen</h3>
        <p>
          Diese Datenschutz-Information gilt für die Datenverarbeitung durch:<br />
          Verantwortlicher: Christine Frank,<br />
          Schweidnitzer Str. 50<br />
          80997 München<br />
          Email: <a href="mailto:webmaster@bibajima.de">webmaster@bibajima.de</a><br />
          Telefon: +49 89 1491527
        </p>

        <h3>2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</h3>
        <h4>a) Beim Besuch der Website</h4>
        <p>
          Beim Aufrufen unserer Website www.bibajima.de werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:
        </p>
        <ul>
          <li>IP-Adresse des anfragenden Rechners</li>
          <li>Datum und Uhrzeit des Zugriffs</li>
          <li>Name und URL der abgerufenen Datei</li>
          <li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
          <li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers</li>
        </ul>
        <p>
          Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:
        </p>
        <ul>
          <li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website</li>
          <li>Gewährleistung einer komfortablen Nutzung unserer Website</li>
          <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
          <li>zu weiteren administrativen Zwecken</li>
        </ul>
        <p>
          Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.
        </p>

        <h4>b) Bei Anmeldung für unseren Newsletter</h4>
        <p>
          Sofern Sie nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrücklich eingewilligt haben, verwenden wir Ihre E-Mail-Adresse dafür, Ihnen regelmäßig unseren Newsletter zu übersenden. Für den Empfang des Newsletters ist die Angabe einer E-Mail-Adresse ausreichend.
          Die Abmeldung ist jederzeit möglich. Sie können Ihren Abmeldewunsch gerne jederzeit an <a href="mailto:webmaster@bibajima.de">webmaster@bibajima.de</a> per E-Mail senden.
        </p>

        <h3>3. Weitergabe von Daten</h3>
        <p>
          Eine Übermittlung Ihrer persönlichen Daten an Dritte findet nicht statt.
        </p>

        <h3>4. Cookies</h3>
        <p>
          Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware. In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten. Wir setzen sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits besucht haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht. Temporäre Cookies, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden, setzen wir nicht ein.
        </p>

        <h3>5. Datensicherheit</h3>
        <p>
          Wir verwenden innerhalb des Website-Besuchs nicht das SSL-Verfahren (Secure Socket Layer). Der Grund ist, dass wir keine weiteren außer der unter Punkt 1 genannten Daten, die keinen Rückschluss auf die aufrufende Person zulassen, und auch keine personenbezogenen Daten übertragen.
        </p>

        <h3>6. Aktualität und Änderung dieser Datenschutzerklärung</h3>
        <p>
          Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Mai 2018. Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website unter <a href="http://www.praxis-dr-hauber.de">http://www.praxis-dr-hauber.de</a> von Ihnen abgerufen und ausgedruckt werden.
        </p>

        <h3>7. Links zu anderen Webseiten</h3>
        <p>
          Wir bieten Ihnen Links zum Aufruf von anderen Webseiten an. Dort werden die unter Punkt 2 angeführten Informationen an den fremden Webserver übermittelt. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den dort angegebenen Datenschutzhinweisen.
        </p>
      </section>
    </div>
  );
};

export default Impressum;
