import React, { useState, useEffect } from "react";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { db, storage } from "../firebase-config";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import "./CSS/CreateEvent.css";

const CreateEvent = () => {
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState(""); // Neues State für die Uhrzeit
  const [location, setLocation] = useState("");
  const [imageUrl, setImageUrl] = useState(""); // Für das Bild
  const [progresspercent, setProgresspercent] = useState(0); // Für den Upload-Progress
  const [isEdit, setIsEdit] = useState(false); // Bearbeitungsstatus
  const { id } = useParams(); // Event-ID aus der URL
  let navigate = useNavigate();

  useEffect(() => {
    // Wenn eine Event-ID vorhanden ist, lade das Event zur Bearbeitung
    if (id) {
      const fetchEvent = async () => {
        const eventDoc = doc(db, "events", id);
        const eventSnap = await getDoc(eventDoc);
        if (eventSnap.exists()) {
          const eventData = eventSnap.data();
          setName(eventData.name);
          setDate(eventData.date);
          setTime(eventData.time);
          setLocation(eventData.location);
          setImageUrl(eventData.imageUrl);
          setIsEdit(true); // Wir sind im Bearbeitungsmodus
        }
      };
      fetchEvent();
    }
  }, [id]);

  const createOrUpdateEvent = async () => {
    if (isEdit) {
      // Event aktualisieren
      const eventDoc = doc(db, "events", id);
      await updateDoc(eventDoc, {
        name,
        date,
        time,
        location,
        imageUrl,
      });
    } else {
      // Neues Event erstellen
      await addDoc(collection(db, "events"), {
        name,
        date,
        time,
        location,
        imageUrl,
      });
    }
    navigate("/calendar");
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `events/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImageUrl(downloadURL);
        });
      }
    );
  };

  return (
    <div className="createEventPage">
      <div className="cpContainer">
        <h1>{isEdit ? "Event bearbeiten" : "Neues Event erstellen"}</h1>
        <div className="inputGp">
          <label>Name:</label>
          <input
            type="text"
            placeholder="Name..."
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="inputGp">
          <label>Datum:</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="inputGp">
          <label>Uhrzeit:</label>
          <input
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          />
        </div>
        <div className="inputGp">
          <label>Ort:</label>
          <input
            type="text"
            placeholder="Ort..."
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <div className="inputGp">
          <label>Bild hochladen:</label>
          <input type="file" onChange={handleImageUpload} />
          {progresspercent > 0 && (
            <div className="outerbar">
              <div className="innerbar" style={{ width: `${progresspercent}%` }}>
                {progresspercent}%
              </div>
            </div>
          )}
          {imageUrl && <img src={imageUrl} alt="Uploaded" height={100} />}
        </div>
        <button onClick={createOrUpdateEvent}>
          {isEdit ? "Event aktualisieren" : "Event speichern"}
        </button>
      </div>
    </div>
  );
};

export default CreateEvent;
