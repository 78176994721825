import React, { useState, useEffect } from "react";
import { addDoc, collection, getDoc, doc, updateDoc } from "firebase/firestore";
import { db, auth, storage } from "../firebase-config";
import { useNavigate, useParams } from "react-router-dom";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CSS/CreatePost.css"; // Importieren Sie die CSS-Datei

function CreatePost({ isAuth }) {
  const [title, setTitle] = useState("");
  const [postText, setPostText] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]); // Initialisierung mit dem aktuellen Datum
  const [isEdit, setIsEdit] = useState(false);
  const [postId, setPostId] = useState(null);

  const postsCollectionRef = collection(db, "posts");
  let navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    if (id) {
      const fetchPost = async () => {
        const docRef = doc(db, "posts", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const postData = docSnap.data();
          setTitle(postData.title);
          setPostText(postData.postText);
          setImageUrls(postData.imageUrls);
          setDate(new Date(postData.timestamp.seconds * 1000).toISOString().split("T")[0]);
          setIsEdit(true);
          setPostId(id);
        } else {
          console.log("No such document!");
        }
      };
      fetchPost();
    }
  }, [id]);

  const createOrUpdatePost = async () => {
    const selectedDate = new Date(date);
    if (isEdit) {
      const postDoc = doc(db, "posts", postId);
      await updateDoc(postDoc, {
        title,
        postText,
        imageUrls,
        timestamp: selectedDate,
      });
    } else {
      await addDoc(postsCollectionRef, {
        title,
        postText,
        author: { name: auth.currentUser.displayName, id: auth.currentUser.uid },
        imageUrls,
        timestamp: selectedDate,
      });
    }
    navigate("/");
  };

  useEffect(() => {
    if (!isAuth) {
      navigate("/login");
    }
  }, [isAuth, navigate]);

  // Image Upload to Cloud Storage
  const [progresspercent, setProgresspercent] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    const files = e.target[0]?.files;
    if (!files) return;

    const uploadPromises = Array.from(files).map((file) => {
      const storageRef = ref(storage, `files/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgresspercent(progress);
          },
          (error) => {
            alert(error);
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    });

    Promise.all(uploadPromises)
      .then((urls) => {
        setImageUrls((prev) => [...prev, ...urls]);
      })
      .catch((error) => console.error("Error uploading images: ", error));
  };

  return (
    <div className="createPostPage">
      <div className="cpContainer">
        <h1>{isEdit ? "Bearbeite den Post" : "Erstelle einen Post"}</h1>
        <div className="inputGp">
          <label> Titel:</label>
          <input
            placeholder="Titel..."
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </div>
        <div className="inputGp">
          <div>
            <label> Text:</label>
            <ReactQuill
              theme="snow"
              value={postText}
              onChange={setPostText}
              placeholder="Text..."
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }, { font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                  ["link", "image"],
                  ["clean"],
                ],
              }}
            />
          </div>
        </div>
        <div className="inputGp">
          <label> Datum:</label>
          <input
            type="date"
            value={date}
            onChange={(event) => setDate(event.target.value)}
          />
        </div>
        <div className="inputGP">
          <label>Bilder:</label>
          <form onSubmit={handleSubmit} className="form">
            <input type="file" multiple />
            <button type="submit">Upload</button>
          </form>
          {imageUrls.length > 0 && (
            <div className="uploadedImages">
              {imageUrls.map((url, index) => (
                <img key={index} src={url} alt={`Uploaded ${index + 1}`} height={200} />
              ))}
            </div>
          )}
          {!imageUrls.length && (
            <div className="outerbar">
              <div className="innerbar" style={{ width: `${progresspercent}%` }}>
                {progresspercent}%
              </div>
            </div>
          )}
        </div>
        <button onClick={createOrUpdatePost}>{isEdit ? "Aktualisieren" : "Speichern"}</button>
      </div>
    </div>
  );
}

export default CreatePost;
