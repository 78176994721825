import React from "react";
import "./CSS/Satzung.css"; // Importieren Sie die CSS-Datei

const Satzung = () => {
  return (
    <div className="satzung-container">
      <h1>Satzung</h1>
      <section>
        <h2>§ 1 Name, Sitz und Geschäftsjahr</h2>
        <p>
          Der Verein führt den Namen "Big Band Jiri Mares".<br />
          Er führt nach Eintragung in das Vereinsregister den Namenszusatz "eingetragener Verein" in der abgekürzten Form "e.V.".<br />
          Der Verein hat seinen Sitz in Gröbenzell.<br />
          Geschäftsjahr ist das Kalenderjahr.
        </p>
      </section>
      <section>
        <h2>§ 2 Gemeinnützigkeit, Zweck des Vereins</h2>
        <p>
          Der Verein verfolgt ausschließlich und unmittelbar gemeinnützige Zwecke im Sinne des Abschnitts "Steuerbegünstigte Zwecke" der Abgabenordnung.<br />
          Der Verein ist selbstlos tätig; er verfolgt nicht in erster Linie eigenwirtschaftliche Zwecke.<br />
          Mittel des Vereins dürfen nur für die satzungsmäßigen Zwecke verwendet werden. Die Mitglieder erhalten keine Zuwendung aus Mitteln des Vereins.<br />
          Es darf keine Person durch Ausgaben, die dem Zweck des Vereins fremd sind, oder durch unverhältnismäßig hohe Vergütungen begünstigt werden.<br />
          Der Verein ist parteipolitisch neutral. Er wird unter Wahrung der politischen und religiösen Freiheit seiner Mitglieder nach demokratischen Grundsätzen geführt.<br />
          Der Verein dient der Förderung der Kunst in Form der Jazz- und Swing Musik mit dem Schwerpunkt Big Band.<br />
          Der Vereinszweck wird insbesondere verwirklicht durch regelmäßige Übungsstunden, musikalische Fortbildung, öffentliche Auftritte und Konzertveranstaltungen.
        </p>
      </section>
      <section>
        <h2>§ 3 Eintragung in das Vereinsregister</h2>
        <p>Der Verein soll in das Vereinsregister eingetragen werden.</p>
      </section>
      <section>
        <h2>§ 4 Eintritt der Mitglieder</h2>
        <p>
          Der Verein besteht nur aus aktiven Mitgliedern.<br />
          Mitglied des Vereins kann jede natürliche Person werden.<br />
          Die Mitgliedschaft entsteht durch Eintritt in den Verein.<br />
          Die Beitrittserklärung ist schriftlich vorzulegen.<br />
          Über die Aufnahme entscheidet der Vorstand. Gegen dessen Entscheidung kann die Mitgliederversammlung angerufen werden, welche endgültig entscheidet.<br />
          Ein Aufnahmeanspruch besteht nicht.
        </p>
      </section>
      <section>
        <h2>§ 5 Austritt der Mitglieder, Tod</h2>
        <p>
          Die Mitglieder sind zum Austritt aus dem Verein berechtigt.<br />
          Der Austritt ist jederzeit möglich.<br />
          Der Austritt ist dem Vorstand schriftlich zu erklären.<br />
          Die Mitgliedschaft endet außerdem durch Tod.
        </p>
      </section>
      <section>
        <h2>§ 6 Ausschluss der Mitglieder</h2>
        <p>
          Die Mitgliedschaft endet ferner durch Ausschluss.<br />
          Der Ausschluss aus dem Verein ist nur bei wichtigem Grund zulässig.<br />
          Über den Ausschluss entscheidet auf Antrag des Vorstands die Mitgliederversammlung.<br />
          Der Vorstand hat seinen Antrag dem auszuschließenden Mitglied mindestens zwei Wochen vor der Versammlung schriftlich mitzuteilen.<br />
          Eine schriftlich eingehende Stellungnahme des Mitglieds ist in der über den Ausschluss entscheidenden Versammlung zu verlesen.<br />
          Der Ausschluss eines Mitglieds wird sofort mit der Beschlussfassung wirksam.<br />
          Der Ausschluss soll dem Mitglied, wenn es bei Beschlussfassung nicht anwesend war, durch den Vorstand unverzüglich eingeschrieben bekanntgemacht werden.
        </p>
      </section>
      <section>
        <h2>§ 7 Rechte und Pflichten der Mitglieder</h2>
        <p>
          Alle Mitglieder haben das Recht, nach den Bestimmungen dieser Satzung an den Versammlungen und Veranstaltungen des Vereins teilzunehmen, Anträge zu stellen und abzustimmen.<br />
          Alle Mitglieder sind verpflichtet, die Ziele und Aufgaben des Vereins zu unterstützen und die Beschlüsse der Organe des Vereins zu beachten.<br />
          Der Verein kann jedem Mitglied eine Vereinskleidung zur Verfügung stellen.<br />
          Diese muss von jedem Mitglied sehr sorgfältig behandelt werden und ist bei Beendigung der Mitgliedschaft unaufgefordert und unbeschädigt in gereinigtem Zustand innerhalb einer Frist von sechs Wochen nach Beendigung der Mitgliedschaft an den Verein zurück zu geben.
        </p>
      </section>
      <section>
        <h2>§ 8 Aufnahmegebühr, Mitgliedsbeitrag</h2>
        <p>Eine Aufnahmegebühr wird nicht erhoben, ein Mitgliedsbeitrag ist nicht zu leisten.</p>
      </section>
      <section>
        <h2>§ 9 Organe des Vereins</h2>
        <p>Organe des Vereins sind der Vorstand und die Mitgliederversammlung.</p>
      </section>
      <section>
        <h2>§ 10 Vorstand</h2>
        <p>
          Der Vorstand besteht aus dem Vorsitzenden, dem stellvertretenden Vorsitzenden, dem Schriftführer, dem Kassier, dem 1. Musikalischen Leiter und bis zu vier Beisitzern mit Stimmrecht.<br />
          Der Vorstand im Sinne des § 26 BGB besteht aus dem Vorsitzenden und dem stellvertretenden Vorsitzenden. Jedes Vorstandsmitglied ist einzelvertretungsberechtigt.<br />
          Der Vorsitzende kann zur Unterstützung seiner Arbeit einzelne Aufgaben sachkundigen Mitgliedern übertragen.<br />
          Der Vorstand wird durch Beschluss der Mitgliederversammlung auf die Dauer von 3 Jahren bestellt. Er bleibt bis zur satzungsgemäßen Bestellung des nächsten Vorstands im Amt.<br />
          Das Amt eines Mitglieds des Vorstands endet mit seinem Ausscheiden aus dem Verein.<br />
          Verschiedene Vorstandsämter können kommissarisch bis zu nächsten Neuwahl in einer Person vereinigt werden. Davon ausgenommen ist eine Verbindung des Amtes des Vorsitzenden mit dem Amt des Kassiers.<br />
          Der Vorstand ist grundsätzlich ehrenamtlich tätig. Die Mitgliederversammlung kann eine jährliche, angemessene pauschale Tätigkeitsvergütung für Vorstandsmitglieder beschließen.
        </p>
      </section>
      <section>
        <h2>§ 11 Berufung der Mitgliederversammlung</h2>
        <p>
          Die Mitgliederversammlung ist zu berufen, wenn es das Interesse des Vereins erfordert, jedoch mindestens jährlich einmal, möglichst in den ersten drei Monaten des Kalenderjahres, nach Ausscheiden eines Mitglieds des Vorstands binnen 3 Monaten.<br />
          In dem Jahr, in dem keine Vorstandswahl stattfindet, hat der Vorstand der nach Abs. 1 zu berufenden Versammlung einen Jahresbericht und eine Jahresabrechnung vorzulegen und die Versammlung über die Entlastung des Vorstands Beschluss zu fassen.
        </p>
      </section>
      <section>
        <h2>§ 12 Form der Berufung</h2>
        <p>
          Die Mitgliederversammlung ist vom Vorstand schriftlich oder per Email unter Einhaltung einer Frist von 2 Wochen zu berufen.<br />
          Die Berufung der Versammlung muss den Gegenstand der Beschlussfassung (= die Tagesordnung) bezeichnen.<br />
          Die Frist beginnt mit dem Tag der Absendung der Einladung an die letzte bekannte Mitgliederanschrift.
        </p>
      </section>
      <section>
        <h2>§ 13 Beschlussfähigkeit</h2>
        <p>
          Beschlussfähig ist jede ordnungsgemäß berufene Mitgliederversammlung.<br />
          Zur Beschlussfassung über die Auflösung des Vereins (§ 41 BGB) ist die Anwesenheit von zwei Dritteln der Vereinsmitglieder erforderlich.<br />
          Ist eine zur Beschlussfassung über die Auflösung des Vereins einberufene Mitgliederversammlung nach Absatz 2 nicht beschlussfähig, so ist vor Ablauf von 4 Wochen seit dem Versammlungstag eine weitere Mitgliederversammlung mit derselben Tagesordnung einzuberufen.<br />
          Die weitere Versammlung darf frühestens 2 Monate nach dem ersten Versammlungstag stattfinden, hat aber jedenfalls spätestens 4 Monate nach diesem Zeitpunkt zu erfolgen.<br />
          Die Einladung zu der weiteren Versammlung hat einen Hinweis auf die erleichterte Beschlussfähigkeit zu enthalten.<br />
          Die neue Versammlung ist ohne Rücksicht auf die Zahl der erschienenen Vereinsmitglieder beschlussfähig.
        </p>
      </section>
      <section>
        <h2>§ 14 Beschlussfassung</h2>
        <p>
          Es wird durch Handzeichen abgestimmt. Auf Antrag von mindestens 5 der Anwesenden ist schriftlich und geheim abzustimmen.<br />
          Bei der Beschlussfassung entscheidet die Mehrheit der erschienenen Mitglieder.<br />
          Zu einem Beschluss, der eine Änderung der Satzung enthält, ist eine Mehrheit von drei Vierteln der erschienenen Mitglieder erforderlich.<br />
          Zur Änderung des Zwecks des Vereins (§ 2 der Satzung) ist die Zustimmung aller Mitglieder erforderlich; die Zustimmung der nicht erschienenen Mitglieder muss schriftlich erfolgen.<br />
          Zur Beschlussfassung über die Auflösung des Vereins (§ 41 BGB) ist eine Mehrheit von vier Fünfteln der erschienenen Mitglieder erforderlich.<br />
          Stimmenthaltungen zählen für die Mehrheiten der erschienenen Mitglieder (Absätze 2, 3 und 5) als NEIN-Stimmen.<br />
          Vollmachten sind zugelassen.
        </p>
      </section>
      <section>
        <h2>§ 15 Beurkundung der Versammlungsbeschlüsse</h2>
        <p>
          Über die in der Versammlung gefassten Beschlüsse ist eine Niederschrift aufzunehmen.<br />
          Die Niederschrift ist von dem Vorsitzenden der Versammlung zu unterschreiben.<br />
          Jedes Vereinsmitglied ist berechtigt, die Niederschrift einzusehen.
        </p>
      </section>
      <section>
        <h2>§ 16 Auflösung des Vereins</h2>
        <p>
          Der Verein kann durch Beschluss der Mitgliederversammlung (vgl. § 14 Abs. 5 der Satzung) aufgelöst werden.<br />
          Die Liquidation erfolgt durch den Vorstand (§ 10 der Satzung)<br />
          Bei Auflösung des Vereins oder bei Wegfall seiner steuerbegünstigten Zwecke fällt das Vermögen des Vereins an eine juristische Person des öffentlichen Rechts oder eine andere steuerbegünstigte Körperschaft zwecks Verwendung für Kultur.
        </p>
      </section>
      <section>
        <h2>Inkrafttreten der Satzung</h2>
        <p>
          Diese Satzung wurde von der außerordentlichen Mitgliederversammlung am 31.Oktober 2018 beschlossen.<br />
          Diese Satzung ersetzt die Satzung vom 16.09.2015
        </p>
        <p>
          Gröbenzell, den 31.Oktober 2018
        </p>
      </section>
    </div>
  );
};

export default Satzung;
