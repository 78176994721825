import React, { useState, useEffect } from "react";
import { auth } from "../firebase-config";
import { updateProfile, updatePassword } from "firebase/auth";
import "./CSS/Profile.css"; // Importieren Sie die CSS-Datei

const Profile = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (auth.currentUser) {
      setEmail(auth.currentUser.email);
      setName(auth.currentUser.displayName || "");
    }
  }, []);

  const handleNameChange = async (e) => {
    e.preventDefault();
    if (auth.currentUser) {
      try {
        await updateProfile(auth.currentUser, { displayName: name });
        setMessage("Name successfully updated.");
      } catch (error) {
        setMessage(error.message);
      }
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (auth.currentUser) {
      try {
        await updatePassword(auth.currentUser, newPassword);
        setMessage("Password successfully updated.");
      } catch (error) {
        setMessage(error.message);
      }
    }
  };

  return (
    <div className="profilePage">
      <h2>Profile</h2>
      {message && <p className="message">{message}</p>}
      <div className="profileInfo">
        <p><strong>Email:</strong> {email}</p>
        <form onSubmit={handleNameChange}>
          <div className="inputGroup">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="updateBtn">Update Name</button>
        </form>
        <form onSubmit={handlePasswordChange}>
          <div className="inputGroup">
            <label htmlFor="newPassword">New Password:</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="updateBtn">Update Password</button>
        </form>
      </div>
    </div>
  );
};

export default Profile;
