import React from "react";
import "./CSS/Musiker.css";
import trompetenImage from "../Images/musiker/trompeten.jpg";
import posaunenImage from "../Images/musiker/posaunen.jpg";
import saxophoneImage from "../Images/musiker/saxophone.jpg";
import gitarristImage from "../Images/musiker/gitarrist.jpg";
import bassistImage from "../Images/musiker/bassist.jpg";
import bandleaderImage from "../Images/musiker/bandleader.jpg";
import saengerinnenImage from "../Images/musiker/saengerinnen.jpg";
import drummerImage from "../Images/musiker/drummer.jpg";

const Musiker = () => {
  return (
    <div className="musikerPage">
      <div className="musikerContainer">
        <h1>Unsere Musiker</h1>
        <div className="register">
          <h2>Bandleader</h2>
          <img src={bandleaderImage} alt="Bandleader" className="registerImage" />
        </div>
        <div className="register">
          <h2>Trompeten</h2>
          <img src={trompetenImage} alt="Trompeten" className="registerImage" />
        </div>
        <div className="register">
          <h2>Posaunen</h2>
          <img src={posaunenImage} alt="Posaunen" className="registerImage" />
        </div>
        <div className="register">
          <h2>Saxophone</h2>
          <img src={saxophoneImage} alt="Saxophone" className="registerImage" />
        </div>
        <div className="register">
          <h2>Gitarrist</h2>
          <img src={gitarristImage} alt="Gitarrist" className="registerImage" />
        </div>
        <div className="register">
          <h2>Bassist</h2>
          <img src={bassistImage} alt="Bassist" className="registerImage" />
        </div>

        <div className="register">
          <h2>Sängerinnen</h2>
          <img src={saengerinnenImage} alt="Sängerinnen" className="registerImage" />
        </div>
        <div className="register">
          <h2>Drummer</h2>
          <img src={drummerImage} alt="Drummer" className="registerImage" />
        </div>
      </div>
    </div>
  );
};

export default Musiker;
