// Newsletter.js
import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase-config';
import emailjs from 'emailjs-com';
import './CSS/Newsletter.css';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'newsletter'), { email });
      sendEmailNotification(email);
      setMessage('Erfolgreich angemeldet!');
      setEmail('');
    } catch (error) {
      console.error('Fehler bei der Anmeldung:', error);
      setMessage('Fehler bei der Anmeldung.');
    }
  };

  const sendEmailNotification = (email) => {
    const templateParams = {
      to_email: email,
      message: 'Vielen Dank für Ihre Anmeldung zu unserem Newsletter!'
    };

    emailjs.send(
      'YOUR_SERVICE_ID', // Ersetzen Sie durch Ihre Service-ID
      'YOUR_TEMPLATE_ID', // Ersetzen Sie durch Ihre Template-ID
      templateParams,
      'YOUR_USER_ID' // Ersetzen Sie durch Ihre User-ID
    ).then((response) => {
      console.log('E-Mail erfolgreich gesendet:', response.status, response.text);
    }).catch((error) => {
      console.error('Fehler beim Senden der E-Mail:', error);
    });
  };

  return (
    <div className="newsletter-container">
      <h2>Newsletter abonnieren</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Ihre E-Mail-Adresse"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Anmelden</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default Newsletter;
