import React, { useEffect, useState } from "react";
import { getDocs, collection, deleteDoc, doc } from "firebase/firestore";
import { auth, db } from "../firebase-config";
import { useNavigate } from "react-router-dom";
import "./CSS/Aktuelles.css";

function News({ isAuth }) {
  const [postLists, setPostList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentPostImages, setCurrentPostImages] = useState([]);
  let navigate = useNavigate();

  const postsCollectionRef = collection(db, "posts");

  // Funktion zum Abrufen der Beiträge
  const getPosts = async () => {
    const data = await getDocs(postsCollectionRef);
    const sortedData = data.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }))
      .sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate()); // Sortieren nach Datum
    setPostList(sortedData);
  };

  useEffect(() => {
    getPosts();
  }, []);

  // Funktion zum Löschen eines Beitrags
  const deletePost = async (id) => {
    const postDoc = doc(db, "posts", id);
    await deleteDoc(postDoc);
    getPosts(); // Aktualisiere die Beitragsliste nach dem Löschen
  };

  // Funktion zum Bearbeiten eines Beitrags
  const editPost = (postId) => {
    navigate(`/edit/${postId}`);
  };

  // Funktion zum Öffnen des Overlays und Festlegen des aktuellen Bildes
  const openOverlay = (images, index) => {
    setCurrentPostImages(images);
    setSelectedImage(images[index]);
    setCurrentImageIndex(index);
  };

  // Funktion zum Schließen des Overlays
  const closeOverlay = () => {
    setSelectedImage(null);
    setCurrentImageIndex(0);
  };

  // Funktion zum Anzeigen des nächsten Bildes
  const showNextImage = () => {
    const nextIndex = (currentImageIndex + 1) % currentPostImages.length;
    setSelectedImage(currentPostImages[nextIndex]);
    setCurrentImageIndex(nextIndex);
  };

  // Funktion zum Anzeigen des vorherigen Bildes
  const showPrevImage = () => {
    const prevIndex = (currentImageIndex - 1 + currentPostImages.length) % currentPostImages.length;
    setSelectedImage(currentPostImages[prevIndex]);
    setCurrentImageIndex(prevIndex);
  };

  return (
    <div className="homePage">
      {postLists.map((post) => (
        <div className="post" key={post.id}>
          <div className="postHeader">
            <div className="title">
              <h1>{post.title}</h1>
              <p>{new Date(post.timestamp.seconds * 1000).toLocaleDateString()}</p>
            </div>
            <div className="postActions">
              {isAuth && post.author.id === auth.currentUser.uid && (
                <>
                  <button onClick={() => editPost(post.id)}>✏️</button>
                  <button onClick={() => deletePost(post.id)}>🗑️</button>
                </>
              )}
            </div>
          </div>
          <div className="postTextContainer" dangerouslySetInnerHTML={{ __html: post.postText }} />
          <div className="postImages">
            {post.imageUrls && post.imageUrls.map((url, index) => (
              <img
                key={index}
                src={url}
                alt={`Post  ${index + 1}`}
                className="postImage"
                onClick={() => openOverlay(post.imageUrls, index)}
              />
            ))}
          </div>
          <h3>@{post.author.name}</h3>
        </div>
      ))}
      {selectedImage && (
        <div className="overlay">
          <span className="close" onClick={closeOverlay}>&times;</span>
          <span className="prev" onClick={showPrevImage}>&#10094;</span>
          <span className="next" onClick={showNextImage}>&#10095;</span>
          <img className="overlayImage" src={selectedImage} alt="Selected" />
        </div>
      )}
    </div>
  );
}

export default News;
