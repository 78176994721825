import React from 'react';
import './CSS/Proben.css';

const Proben = () => {
  return (
    <div className="probenPage">
      <h1>Proben</h1>
      <p>Wir proben immer Donnerstag abends von 19:30 Uhr bis ca. 22:00 Uhr.</p>
      <p>Ihr findet uns dann im Keller der Wildmooshalle, rechts vorbei an der Gaststätte Wildmoos und die Treppe runter zum Trachtenstüberl. Ihr werdet uns nicht überhören.</p>
      <p>In der Regel halten wir uns an die bayerischen Ferien, so dass wir nur zu Schulzeiten proben. Eine kurze Nachfrage bringt Euch auf die sichere Seite.</p>
      <h1>Wir suchen Mitglieder!</h1>
      <p>Willkommen sind alle Bigband-Instrumente, besonders suchen wir allerdings einen Keyboarder und Schlagzeuger.</p>
    </div>
  );
};

export default Proben;
