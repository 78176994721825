import React, { useState, useEffect } from "react";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase-config";
import "./CSS/CalendarView.css";

const CalendarView = () => {
  const [events, setEvents] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsCollection = collection(db, "events");
      const eventsSnapshot = await getDocs(eventsCollection);
      const eventsList = eventsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const filteredAndSortedEvents = eventsList
        .filter(event => new Date(event.date) >= new Date().setHours(0, 0, 0, 0))
        .sort((a, b) => new Date(a.date) - new Date(b.date));

      setEvents(filteredAndSortedEvents);
    };

    fetchEvents();
  }, []);

  const handleDeleteEvent = (id) => {
    setEventToDelete(id);
    setShowConfirm(true);
  };

  const confirmDelete = async () => {
    if (eventToDelete) {
      const eventDoc = doc(db, "events", eventToDelete);
      await deleteDoc(eventDoc);
      setEvents(events.filter(event => event.id !== eventToDelete));
      setShowConfirm(false);
      setEventToDelete(null);
    }
  };

  const handleEditEvent = (id) => {
    navigate(`/edit-event/${id}`); // Navigiere zur Bearbeitungsseite
  };

  const cancelDelete = () => {
    setShowConfirm(false);
    setEventToDelete(null);
  };

  const formatDate = (date) => {
    const options = { day: "numeric" };
    const day = new Date(date).toLocaleDateString("de-DE", options);
    const month = new Date(date).toLocaleString("de-DE", { month: "short" }).toUpperCase();
    return { day, month };
  };

  return (
    <div className="calendarPage">
      <h2 className="calendarHeader">Unsere nächsten Termine</h2>
      <div className="eventCardsContainer">
        {events.map(event => {
          const { day, month } = formatDate(event.date);
          return (
            <div className="eventCard" key={event.id}>
              <div className="eventCardDate">
                <span className="eventDay">{day}</span>
                <span className="eventMonth">{month}</span>
              </div>
              <div className="eventCardContent">
                <div className="eventCardHeader">
                  <h3>{event.name}</h3>
                  
                </div>
                <div className="eventCardBody">
                  <p>{event.location}</p>
                  {auth.currentUser && (
                <div className="eventCardActions">
                  <button className="editButton" onClick={() => handleEditEvent(event.id)}>Bearbeiten</button>
                  <button className="deleteButton" onClick={() => handleDeleteEvent(event.id)}>Löschen</button>
                </div>
              )}
                </div>
              </div>
              <div className="eventCardImage">
                {event.imageUrl ? (
                  <img src={event.imageUrl} alt="Event" height={100} />
                ) : (
                  <img src="https://via.placeholder.com/150" alt="Platzhalter" height={100} />
                )}
              </div>
        
            </div>
          );
        })}
      </div>

      {showConfirm && (
        <div className="confirmDeletePopup">
          <div className="popupContent">
            <p>Möchten Sie dieses Event wirklich löschen?</p>
            <button className="confirmButton" onClick={confirmDelete}>Ja, löschen</button>
            <button className="cancelButton" onClick={cancelDelete}>Abbrechen</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarView;
