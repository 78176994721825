import React from "react";
import "./CSS/ÜberUns.css";
import bandImage1 from "../Images/bandImage1.jpg";
import bandImage2 from "../Images/bandImage2.jpg";

const ÜberUns = () => {
  return (
    <div className="aboutPage">
      <div className="aboutContainer">
        <h1>Über Uns</h1>
        <p>
          Die Bigband Jiri Mares wurde im Jahre 1987 von Jiri Mares gegründet und gehörte bis 1992 zur Kreismusikschule Fürstenfeldbruck, Musikschule Olching.
          Sie besteht vorwiegend aus jazzbegeisterten Amateurmusikern aller Altersklassen aus der Umgebung von München, die die Liebe zur Bigband-Musik miteinander verbindet.
        </p>
        <p>
          Besetzt ist die Bigband mit 5 Saxophonen, 4 Trompeten, 4 Posaunen, Piano, Bass, Gitarre und Schlagzeug, also typische Bigband-Besetzung. In Abhängigkeit vom Auftrittsprogramm wird die Bigband durch Sänger verstärkt.
          Seit der Gründung hat sich die Besetzung der Band zwar immer wieder verändert, aber trotzdem blieb das Repertoire immer seinem ursprünglichen Stil treu. Das Programm umfasst neben bekannten Melodien vom Glenn Miller Orchester, wie z.B. Strings of Pearls, In the Mood, genauso fetzigen Jazz Rock oder Rock n' Roll. Das bunt gemischte Repertoire bietet somit für jeden musikbegeisterten Zuhörer etwas.
          Seit einigen Jahren bietet die Bigband zur Weihnachtszeit auch ein "Swing-Weihnachtsprogramm" an.
        </p>
        <img src={bandImage1} alt="Band Image 1" className="aboutImage" />
        <p>
          Ende 2011 wurde die Band ein eingetragener Verein.
        </p>
        <p>
          Ihre Auftritte hatte die Bigband bisher bei vielen Jazzfrühschoppen und Kulturveranstaltungen im und außerhalb des Landkreises Fürstenfeldbruck, wie z.B. beim Jazzweekend Regensburg, Kultursommer Emmering, Kulturnacht Fürstenfeldbruck (Stadthalle), Volksfeste in Olching und Ergoldsbach, Bürgerhaus Emmering, Schloßwirtschaft Weyhern, etc. sowie bei "viva la musica" Maisach mit Rundfunkübertragung. Im Februar 2003 war die Bigband anlässlich der Sendung "B1 unterwegs" im Bayerischen Rundfunk zu hören. Darüber hinaus veranstaltet die Bigband auch eigene Konzerte und spielt bei verschiedenen privaten Veranstaltungen.
        </p>
        <img src={bandImage2} alt="Band Image 2" className="aboutImage" />
        <p>
          Seit Dezember 2022 steht die Bigband unter einer neuen musikalischen Leitung von Steffen Schmitt.
        </p>
      </div>
    </div>
  );
};

export default ÜberUns;
