import React, { useState } from "react";
import "./CSS/Anfahrt.css"; // Importieren Sie die CSS-Datei

const Anfahrt = () => {
  const [consent, setConsent] = useState(false);

  const handleConsent = () => {
    setConsent(true);
  };

  return (
    <div className="anfahrt-container">
      <h1>Anfahrt</h1>
      <p>
        Die Proben finden im Gebäude der Gaststätte Wildmoos in Gröbenzell statt, jedoch nicht in der Gaststätte selbst.
      </p>
      <div className="map-container">
        {consent ? (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d10694.494946943896!2d11.38301282620797!3d48.19170294127952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x479e78df015c3af5%3A0xbe5608ad03e30034!2sRestaurant%20Wildmoos%2C%20Wildmoosstra%C3%9Fe%2036%2C%2082194%20Gr%C3%B6benzell!3m2!1d48.191759!2d11.383029!4m5!1s0x479e78df015c3af5%3A0xbe5608ad03e30034!2sWildmoosstra%C3%9Fe%2036%2C%2082194%20Gr%C3%B6benzell%2C%20Germany!3m2!1d48.191759!2d11.383029!5e0!3m2!1sen!2sus!4v1646762758055!5m2!1sen!2sus"
            width="80%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="Wildmoos Gröbenzell"
          ></iframe>
        ) : (
          <div className="consent-container">
            <p>
              Diese Karte wird von Google Maps eingebettet. Durch das Laden der Karte können Cookies und andere Daten von Google Maps erfasst werden. Möchten Sie die Karte laden?
            </p>
            <button onClick={handleConsent}>Karte laden</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Anfahrt;
