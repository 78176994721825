import React from "react";
import "./CSS/JiriMares.css";
import jiriMaresImage from "../Images/jiriMaresImage.png";

const JiriMares = () => {
  return (
    <div className="jiriMaresPage">
      <div className="jiriMaresContainer">
        <h1>Jiri Mares</h1>
        <img src={jiriMaresImage} alt="Jiri Mares" className="jiriMaresImage" />
        <p>
          Seine Bigband gegründet hat Jiri Mares 1987. Damals war er Musiklehrer an der Kreismusikschule Fürstenfeldbruck.
          Er ist der musikalische Leiter der Bigband. Außerdem sind sehr viele unserer Stücke von ihm arrangiert.
        </p>
        <p>
          Geboren wurde Jiri Mares 1939 in Prag.
          Schon als Kind spielte er Klavier, lernte Schlagzeug und studierte bald Gitarre am nahegelegenen Konservatorium.
          Er lernte zwar trotzdem einen handwerklichen Beruf, suchte sich aber eine Anstellung als Schlagzeuger in einer professionellen Band. Mit dieser Band machte er längere Gastspielreisen ins damalige Jugoslawien und auch nach Deutschland.
        </p>
        <p>
          Infolge der politischen Unruhen des Prager Frühlings kam Jiri Mares 1971 nach Fürstenfeldbruck. Er wurde Lehrer an der Kreismusikschule in den Fächern Gitarre und Schlagzeug und konnte seine Erfahrungen und sein musiktheoretisches Wissen an jüngere Generationen weitergeben.
        </p>
        <p>
          Im heutigen Repertoire der Jiri Mares Bigband sind all die musikalischen Stationen präsent, die den Bandleader im Laufe seines Lebens geprägt haben: Swingnummern von Glenn Miller und Duke Ellington, Titel die durch Frank Sinatra und Ray Charles bekannt wurden und auch moderne Kompositionen von Carlos Santana, George Benson, Stevie Wonder, Henri Mancini und Joe Zavinul.
          Spätestens, wenn er Samba Pa Ti spielt, merken Sie, dass Jiri ein großer Fan von Carlos Santana ist.
        </p>
      </div>
    </div>
  );
};

export default JiriMares;
